exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-backline-en-tsx": () => import("./../../../src/pages/backline.en.tsx" /* webpackChunkName: "component---src-pages-backline-en-tsx" */),
  "component---src-pages-backline-hu-tsx": () => import("./../../../src/pages/backline.hu.tsx" /* webpackChunkName: "component---src-pages-backline-hu-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-hu-tsx": () => import("./../../../src/pages/index.hu.tsx" /* webpackChunkName: "component---src-pages-index-hu-tsx" */),
  "component---src-pages-references-en-tsx": () => import("./../../../src/pages/references.en.tsx" /* webpackChunkName: "component---src-pages-references-en-tsx" */),
  "component---src-pages-references-hu-tsx": () => import("./../../../src/pages/references.hu.tsx" /* webpackChunkName: "component---src-pages-references-hu-tsx" */),
  "component---src-pages-xlnt-records-en-tsx": () => import("./../../../src/pages/xlnt-records.en.tsx" /* webpackChunkName: "component---src-pages-xlnt-records-en-tsx" */),
  "component---src-pages-xlnt-records-hu-tsx": () => import("./../../../src/pages/xlnt-records.hu.tsx" /* webpackChunkName: "component---src-pages-xlnt-records-hu-tsx" */),
  "component---src-templates-artist-tsx": () => import("./../../../src/templates/artist.tsx" /* webpackChunkName: "component---src-templates-artist-tsx" */),
  "component---src-templates-backline-content-tsx": () => import("./../../../src/templates/backlineContent.tsx" /* webpackChunkName: "component---src-templates-backline-content-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */)
}

